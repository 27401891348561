import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Container, FormDiv, ButtonCust } from "../styles/Product.style";
import { product_list, product_list_total } from "../services/product";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import useWindowWidth from '../../../reusable/useWindowWidth';
import {
    TableContainer, Table, TableHead, IconButton, Box,
    TableRow, TableCell, TableBody, TablePagination, CircularProgress,
    Typography, TextField, Button, useMediaQuery, useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BUTTON_STYLE } from "../../../../constants/style";

const StyledContainer = styled(Container)({
    padding: '20px',
    marginTop: '20px',
});

const StyledTableContainer = styled(TableContainer)({
    maxHeight: 700,
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
});
const CancelIconDIv = styled("div")({
    position: "relative",
});
const CancelFilterIcon = styled(CancelIcon)({
    position: "absolute",
    right: "-9px",
    zIndex: "1",
    top: "-14px",
    background: "white",
    borderRadius: "10px",
    cursor: "pointer"
})

const ProductList: React.FC = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const width = useWindowWidth();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [stockQtyStart, setStockQtyStart] = useState<number | string>('');
    const [stockQtyEnd, setStockQtyEnd] = useState<number | string>('');
    const [searchInput, setSearchInput] = useState<string>('');

    useEffect(() => {
        fetchProductTotal();
    }, []);

    useEffect(() => {
        fetchProducts();
    }, [page, rowsPerPage]);

    const fetchProductTotal = async () => {
        fetchProductListTotal(searchInput, stockQtyStart, stockQtyEnd)
    };
    const fetchProductListTotal = async (search: string, stockQtyStart: number | string, stockQtyEnd: number | string) => {
        const response: any = await product_list_total(search, stockQtyStart, stockQtyEnd);
        setTotalRows(response && response.data?.data && response.data?.data?.length > 0 ? response.data?.data[0].total : 0);

    }
    const fetchProducts = async () => {
        await fetchProductList(page + 1, rowsPerPage, stockQtyStart, stockQtyEnd, searchInput);
    };
    const fetchProductList = async (page: number, rowsPerPage: number, stockQtyStart: number | string, stockQtyEnd: number | string, searchInput: string) => {
        setLoading(true);
        try {
            const response: any = await product_list(page, rowsPerPage, stockQtyStart, stockQtyEnd, searchInput);
            setProducts(response.data.data);
        } catch (error) {
            console.error('Error fetching products:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleSearch = () => {
        setPage(0);
        fetchProducts();  // Trigger the API call when the search button is clicked
        fetchProductTotal();
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleEdit = (productId: number) => {
        navigate(`/admin/product_edit/${productId}`);
    };

    const handleCreate = () => {
        navigate(`/admin/product`);
    };

    const handleStockQtyStartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStockQtyStart(event.target.value);
    };

    const handleStockQtyEndChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStockQtyEnd(event.target.value);
    };
    const clearFilter = async () => {
        setPage(0);
        setStockQtyStart("")
        setStockQtyEnd("");
        setSearchInput("");
        fetchProductList(1, rowsPerPage, '', '', "");
        fetchProductListTotal("", "", "")
    }

    return (
        <Container>

            <FormDiv>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h6">Product List</Typography>
                    <ButtonCust onClick={handleCreate} sx={BUTTON_STYLE}>
                        Add
                    </ButtonCust>
                </Box>

                {/* Stock Quantity Filters */}
                <Box sx={{ margin: '20px 0', display: 'flex', gap: '16px' }}>
                    <TextField
                        label="Search Product"
                        variant="outlined"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        type="text"
                        size="small"
                        placeholder="Search your product"
                    />
                    <TextField
                        label="Stock Quantity Start"
                        variant="outlined"
                        value={stockQtyStart}
                        onChange={handleStockQtyStartChange}
                        type="number"
                        size="small"
                        placeholder="Enter min stock quantity"
                    />
                    <TextField
                        label="Stock Quantity End"
                        variant="outlined"
                        value={stockQtyEnd}
                        onChange={handleStockQtyEndChange}
                        type="number"
                        size="small"
                        placeholder="Enter max stock quantity"
                    />
                    <CancelIconDIv>
                        {
                            stockQtyStart != "" && stockQtyEnd != "" &&
                            <CancelFilterIcon onClick={() => {
                                clearFilter()
                            }} />
                        }

                        <Button variant="contained" color="primary" onClick={handleSearch}>
                            Search
                        </Button>
                    </CancelIconDIv>

                </Box>

                <StyledContainer>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <div
                            style={{
                                width: isMobile ? (width - 60) + "px" : "100%",
                                height: '100vh',
                                overflowX: 'auto',
                                overflowY: 'hidden',
                                whiteSpace: 'nowrap',
                            }}
                        >

                            <TableContainer style={{
                                width: isMobile ? '700px' : '100%',
                                height: "800px",
                                maxHeight: 800,
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>No.</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Category</TableCell>
                                            <TableCell>Sub-Category</TableCell>
                                            <TableCell>Created Date</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {products.map((product: any, index) => (
                                            <TableRow key={product.productId}>
                                                <TableCell size="small">{product.productId}</TableCell>
                                                <TableCell size="small">{product.productName}</TableCell>
                                                <TableCell size="small">{product.categoryName}</TableCell>
                                                <TableCell size="small">{product.subCategoryName}</TableCell>
                                                <TableCell size="small">{new Date(product.createdDttm).toLocaleDateString()}</TableCell>
                                                <TableCell size="small">
                                                    <IconButton onClick={() => handleEdit(product.productId)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50]}
                                    component="div"
                                    count={totalRows}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </div>
                    )}
                </StyledContainer>
            </FormDiv>
        </Container>
    );
};

export default ProductList;
