import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { Container, FormDiv, ButtonCust } from "../styles/Product.style";
import { order_list, order_list_total } from "../services/order";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { 
    TableContainer, Table, TableHead, IconButton, Box,
    TableRow, TableCell, TableBody, TablePagination, CircularProgress, Chip,  
    Typography, TextField ,Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';     
import { BUTTON_STYLE } from "../../../../constants/style";

const StyledContainer = styled(Container)({
    padding: '20px',
    marginTop: '20px',
});

const StyledTableContainer = styled(TableContainer)({
    maxHeight: 700,
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
});

const ProductList: React.FC = () => {
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [type, setType] = useState('All');

    const handleChange = (event:any) => {
      setType(event.target.value as string);
    };
    useEffect(() => {
        fetchProductTotal();
    }, []);
    useEffect(()=>{
        if((startDate && endDate) ||type){

            fetchProductTotal();
        }
    },[startDate, endDate,type])
    useEffect(() => {
        fetchOrders();
    }, [page, rowsPerPage, startDate, endDate,type]);

    const fetchProductTotal = async () => {
        const response: any = await order_list_total(startDate ? ""+new Date(startDate).getTime()+"" : "" , endDate ? ""+new Date(endDate).getTime()+"" : "", type ? type : "All");
        setTotalRows(response && response.data?.orderstotal && response.data?.orderstotal.length > 0 
            ? response.data?.orderstotal[0].orderTotal : 0);
    };

    const fetchOrders = async () => {
        setLoading(true);
        try {
            const response: any = await order_list(page + 1, rowsPerPage, 
              startDate ? ""+new Date(startDate).getTime()+"" : "" , endDate ? ""+new Date(endDate).getTime()+"" : "", type ? type : "All");
            setOrders(response.data.orders);
        } catch (error) {
            console.error('Error fetching orders:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleView = (productId: number) => {
        navigate(`/admin/order_edit/${productId}`);
    };
    const handleEdit = (productId: number) => {
        navigate(`/admin/order_update/${productId}`);
    };

    const handleCreate = () => {
        navigate(`/admin/order/create`);
    };

    const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(event.target.value);
    };

    return (
        <Container>
            <FormDiv>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h6">Order List</Typography>
                    <ButtonCust onClick={handleCreate} sx={BUTTON_STYLE}>
                        Add
                    </ButtonCust>
                </Box>

                {/* Start and End Date Filters */}
                <Box sx={{ display: 'flex', gap: '16px', marginTop: '20px' }}>
                    <TextField
                        label="Start Date"
                        type="date"
                        size="small"
                        value={startDate || ''}
                        onChange={handleStartDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        label="End Date"
                        type="date"
                        size="small"
                        value={endDate || ''}
                        onChange={handleEndDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <FormControl 
                    size="small"
                    sx={{width:100}}
                    >
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Type"
                        value={type}
                        onChange={handleChange}
                    >
                        <MenuItem value={"W"}>W</MenuItem>
                        <MenuItem value={"G"}>G</MenuItem>
                        <MenuItem value={"All"}>All</MenuItem>
                    </Select>
                    </FormControl>
                </Box>

                <StyledContainer>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <StyledTableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell size="medium">No.</TableCell>
                                        <TableCell size="medium">User</TableCell>
                                        <TableCell size="medium">Order Date</TableCell>
                                        <TableCell size="medium">Qty</TableCell>
                                        <TableCell size="medium">Total Price</TableCell>
                                        <TableCell size="medium">Status</TableCell>
                                        <TableCell size="medium">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orders && orders.length > 0 && orders.map((order: any, index) => (
                                        <TableRow key={order.id}>
                                            <TableCell size="small">{order.id}</TableCell>
                                            <TableCell size="small">{order.fullName}  <br/>{order.mobileNo}</TableCell>
                                            <TableCell size="small">{new Date(order.orderDate).toLocaleDateString()}</TableCell>
                                            <TableCell size="small">{order.totalQty}</TableCell>
                                            <TableCell size="small">{order.totalPrice}</TableCell>
                                            <TableCell size="small">
                                                <Chip label={order.status} color="primary" size="small" />
                                            </TableCell>
                                            <TableCell size="small">
                                                <IconButton onClick={() => handleEdit(order.id)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton onClick={() => handleView(order.id)}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={totalRows}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </StyledTableContainer>
                    )}
                </StyledContainer>
            </FormDiv>  
        </Container>
    );
};

export default ProductList;
