import React,{useState} from "react";
import { Grid, Box, Typography, IconButton, TextField, FormControl,
  Dialog,DialogActions,DialogTitle,Button,Select,InputLabel,MenuItem
 } from "@mui/material";
 import TableViewIcon from '@mui/icons-material/TableView';
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from '@mui/system';

const ProductContainer = styled(Box)`
  border: 1px solid #e0e0e0;
  padding: 16px;
  border-radius: 8px;
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProductDetails = styled(Grid)`
  
`;// display: flex;
// align-items: center;

const ProductActions = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

interface OrderProductBoxProps {
  product: any;
  onQtyChange: (newQty: number) => void;
  deleteOrderedProduct:any;
  units:any;
  onUnitChange:any;
  gstRates:any;
  onPriceChange:any;
  withGst:number;
  onGstChange:any;
}

const OrderProductBox = ({ product, onQtyChange,deleteOrderedProduct,units,onUnitChange,gstRates ,onGstChange,
  onPriceChange,withGst}: OrderProductBoxProps) => {
  const [errorMessage,setErrorMessage] = useState("");
  const [openError, setOpenError] = useState(false);
  const [temporaryPrice,setTemporaryPrice] = useState(product.attribute.price_per_unit);
  const [subUnits,setSubUnits] = useState(units && units.length > 0 ?
    units.filter((e:any)=> product.attribute.base_unit === e.base_unit) : []
  )
  console.log("temporaryPrice",temporaryPrice, product.attribute.price_per_unit)
  const handleQtyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(Number(event.target.value) == 0){
      setErrorMessage("Product qty is must be greater than zero.")
      // setOpenError(true);
      onQtyChange(Number(event.target.value));
    }else{
      if(product && ((event.target.value <= product.attribute.stockQty) || (product.attribute.base_unit !== product.attribute.unit))) {
        setErrorMessage("")
        onQtyChange(Number(event.target.value));
      }else{
        setErrorMessage("Product qty is out of stock.")
        // setOpenError(true);
      }
    }
    
  };
  const handleCloseError = () => {
    setOpenError(false);
  };
  const handleChangeUnit = (event:any) => {
   if(event.target.value){
    onUnitChange(product,event.target.value);
   }
  }
  const handleChangeGst = (event:any) => {
     onGstChange(product,event.target.value);
   }
   const handlePriceChange = (event:any) => {
    onPriceChange(product,event.target.value);
   }
  return (
    <ProductContainer>
      <ProductDetails container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Typography variant="body2">{product.name}</Typography>
          {
            product.attribute.rackName && <>
              <Typography variant="body2" style={{color: "grey",fontSize: "12px",display: "flex"}}>
            <TableViewIcon style={{fontSize: "15px"}}/>
          <label >{product.attribute.rackName}</label></Typography>
          <Typography variant="body2" style={{color: "grey",fontSize: "12px"}}>{product.attribute.rackSectionName}</Typography>
      
      </>  
          }
          <Typography variant="body2">
            {
              product.attribute.mainAttributeName === "None" ? <><label style={{color:"grey"}}> Attr: </label>None</>: 
              <><label style={{color:"grey"}}> {product.attribute.mainAttributeName}: </label>{product.attribute.attributeName}</>
              
            }
          </Typography>

          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={1}>
          <FormControl fullWidth size="small">
            <TextField
              label="Qty"
              id="orderQty"
              type="number"
              size="small"
              value={product.orderQty}
              onChange={handleQtyChange}
              InputProps={{ inputProps: { min: 0 } }}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={1}>
        <FormControl fullWidth size="small"> 
              <InputLabel>
                Unit <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <Select
                name="unit"
                label="Unit *"
                value={product.attribute.unit}
                onChange={handleChangeUnit}
                fullWidth
              >{
                subUnits && subUnits.length > 0 && (
                  subUnits.map((e:any,index:number)=>{
                    return (
                      <MenuItem value={e.unit} key={"Unit"+index}>{e.unit}</MenuItem>
                    )
                  })
                )
              }
              </Select>
            </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          {/* <Typography variant="body2">
           <label style={{color:"grey"}}> Price per</label> {product.attribute.unit}: ₹{(product.attribute.price - product.attribute.gst_per_price).toFixed(4) }
          </Typography> */}
          <FormControl fullWidth size="small">
            <TextField
              label={<><label style={{color:"grey"}}> Price per</label> {product.attribute.unit}:</>}
              id="orderPrice"
              type="number"
              size="small"
              value={temporaryPrice}
              onChange={(e) =>  setTemporaryPrice(e.target.value)}
              onBlur={handlePriceChange}
              InputProps={{ inputProps: { min: 0 } }}
              variant="outlined"
            />
          </FormControl>
        </Grid>
       
        <Grid item xs={12} sm={12} md={3} lg={1}>
          <Typography variant="body2">
          <label  style={{color:"grey"}}> Stock: </label>   {product.attribute.stockQty}
            {product.attribute.base_unit}
          </Typography>
        </Grid>
      
       
        {
          withGst === 1 && <Grid item xs={12} sm={12} md={3} lg={1}>
          <FormControl fullWidth size="small"> 
                <InputLabel>
                GST <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <Select
                  name="gstRate"
                  label="GST *"
                  value={product.attribute.gst_percentage}
                  onChange={handleChangeGst}
                  fullWidth
                >{
                  gstRates && gstRates.length > 0 && (
                    gstRates.map((e:any,index:number)=>{
                      return (
                        <MenuItem value={e.rate} key={"Unit"+index}>{e.rate}% </MenuItem>
                      )
                    })
                  )
                }
                </Select>
              </FormControl>
          </Grid>
        }
        
       
        <Grid item xs={12} sm={6} md={6} lg={2}>
          <Typography variant="body2">
          <label  style={{color:"grey"}}> Subtotal: </label> ₹{(product.attribute.price * product.orderQty).toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={1}>
        <IconButton color="error" aria-label="delete" onClick={()=>{
            deleteOrderedProduct(product.attribute.id)
        }}>
          <DeleteIcon />
        </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
        <label style={{color:"red"}}>{errorMessage}</label>
        </Grid>
      </ProductDetails>
      {/* <ProductActions item xs={1}>
       
      </ProductActions>  */}
      
      <Dialog
        open={openError}
        onClose={handleCloseError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
              {errorMessage}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseError}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </ProductContainer>
  );
};

export default OrderProductBox;
