export const HEADING_COLOR = "white" 
export const SHADOW_COLOR = "white"
export const BUTTON_STYLE:any = {
    textTransform: 'none',
  // background: "#feada6",
  background: "rgb(149, 183, 192)",
  boxShadow: "rgb(212 205 204) 0px 0px 7px 3px",
  color:"white",
  ":hover":{
   color:"black"
  }
}
export const CANCEL_BUTTON_STYLE:any = {
  textTransform: 'none',
background: "#bab6b6",
boxShadow: "rgb(212 205 204) 0px 0px 7px 3px",
color:"white",
":hover":{
   color:"black"
  }
}


