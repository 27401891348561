import React from 'react';
import OrderUpdateUI from "../../components/admin/order/components/OrderUpdate";
import PersistentDrawerLeft from "../../components/reusable/admin/Main";
const OrderDetail = () => {
  return (
    <div>
      <PersistentDrawerLeft>
      <OrderUpdateUI />
      </PersistentDrawerLeft>
    </div>
  );
};

export default OrderDetail;