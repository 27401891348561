import React, { useState, useEffect } from "react";
import { Grid, Card, Typography } from "@mui/material";
import { Container } from "../styles/Dashboard.style";
import {
  getProductCount, getOrderCount, getTotalCompletedOrderAmount, getOutOfStockCount,
  getOrdersPerDay, getTotalPricePerDay
} from "../services/dashboard";
import OrderPerDayUI from "./OrderPerDay";
import TotalPricePerDay from "./TotalPricePerDay";
const Dashboard: React.FC = () => {
  const [productCount, setProductCount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [totalCompletedOrderAmount, setTotalCompletedOrderAmount] = useState(0);
  const [outOfStockCount, setOutOfStockCount] = useState(0);
  const [ordersPerDay, setOrdersPerDay] = useState([]);
  const [totalPricePerDay, setTotalPricePerDay] = useState([]);

  useEffect(() => {
    fetchProductCount();
    fetchOrderCount();
    fetchTotalCompletedOrderAmount();
    fetchOutOfStockCount();
    fetchOrdersPerDay();
    fetchTotalPricePerDay();
  }, [])
  const fetchProductCount = async () => {
    try {
      const response: any = await getProductCount();
      setProductCount(response.data?.totalCount || 0)
    } catch (error) {

    }
  }
  const fetchOrderCount = async () => {
    try {
      const response: any = await getOrderCount();
      setOrderCount(response.data?.totalCount || 0)
    } catch (error) {

    }
  }
  const fetchTotalCompletedOrderAmount = async () => {
    try {
      const response: any = await getTotalCompletedOrderAmount();
      setTotalCompletedOrderAmount(response.data?.totalCompletedAmount || 0)
    } catch (error) {
    }
  }
  const fetchOutOfStockCount = async () => {
    try {
      const response: any = await getOutOfStockCount();
      setOutOfStockCount(response.data?.outOfStockCount || 0)
    } catch (error) {
    }
  }
  const fetchOrdersPerDay = async () => {
    try {
      const response: any = await getOrdersPerDay();
      setOrdersPerDay(response.data?.ordersPerDay)
    } catch (error) {
    }
  }
  const fetchTotalPricePerDay = async () => {
    try {
      const response: any = await getTotalPricePerDay();
      setTotalPricePerDay(response.data?.totalPricePerDay)
    } catch (error) {
    }
  }

  const styles = {
    backgroundColor: "#dadfdf8c",
    padding: "11px",
    marginTop: "10px",
    borderRadius: "20px"
  }
  const stylestext = {
    fontSize: "20px",
    fontFamily: "auto",
    fontStyle: "italic",
  }
  const styleshesding = {
    // fontSize: "20px",
    fontFamily: "auto",
    fontStyle: "inherit",
  }
  return (
    <Container>
      <div style={styles}>
        <h4 style={styleshesding}>Dashboard W</h4>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{
              width: "100%", height: "100px",
              // backgroundColor: "rgb(203, 220, 235)",
              backgroundColor: "rgb(255, 255, 255)",
              backgroundImage: "linear-gradient(45deg, rgba(197, 255, 247, 0.61), transparent)", borderRadius: "10px",
              boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px"
            }}>
              <Typography variant="h4" style={{ marginTop: "20px", textAlign: "center" }}>
                {productCount}
              </Typography>
              <Typography variant="body2" style={{ marginTop: "10px", marginBottom: "10px", textAlign: "center" }}>
                Products
              </Typography>

            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{
              width: "100%", height: "100px",
              boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
              // backgroundColor: "rgb(152, 211, 255)"
              backgroundColor: "rgb(255, 255, 255)",
              backgroundImage: "linear-gradient(45deg, rgba(197, 255, 247, 0.61), transparent)", borderRadius: "10px",
            }}>
              <Typography variant="h4" style={{ marginTop: "20px", textAlign: "center" }}>
                {orderCount}
              </Typography>
              <Typography variant="body2" style={{ marginTop: "10px", marginBottom: "10px", textAlign: "center" }}>
                Order Count
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{
              width: "100%", height: "100px",
              boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
              // backgroundColor: "rgb(214, 240, 255)"
              backgroundColor: "rgb(255, 255, 255)",
              backgroundImage: "linear-gradient(45deg, rgba(197, 255, 247, 0.61), transparent)", borderRadius: "10px",
            }}>
              <Typography variant="h4" style={{ marginTop: "20px", textAlign: "center" }}>
                {totalCompletedOrderAmount}
              </Typography>
              <Typography variant="body2" style={{ marginTop: "10px", marginBottom: "10px", textAlign: "center" }}>
                Total Amount
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card sx={{
              width: "100%", height: "100px",
              boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
              // backgroundColor: "rgb(255, 145, 145)"
              backgroundColor: "#ffffff",
              backgroundImage: "linear-gradient(45deg, rgb(246 197 197 / 61%), #0000004a)", borderRadius: "10px",

            }}>
              <Typography variant="h4" style={{ marginTop: "20px", textAlign: "center" }}>
                {outOfStockCount}
              </Typography>
              <Typography variant="body2" style={{ marginTop: "10px", marginBottom: "10px", textAlign: "center" }}>
                Out of Stock Products
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Card style={{ padding: "10px", borderRadius: "10px" }}>
              <Typography variant="h5" style={stylestext}>Orders counts of current month</Typography>
              <OrderPerDayUI data={ordersPerDay} />
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Card style={{ padding: "10px", borderRadius: "10px" }}>
              <Typography variant="h5" style={stylestext}>Orders amount of current month</Typography>
              <TotalPricePerDay data={totalPricePerDay} />
            </Card>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}
export default Dashboard;

