import React,{useEffect }from 'react';
import { Route, Routes, Navigate, useNavigate  } from 'react-router-dom';
import Login from "./login";
import Dashboard from "./admin/dashboard";
import DashboardWithGst from "./admin/dashboard_with_gst";
import Product from './admin/product';
import AdminProductList from "./admin/productList";
import AdminProductEdit from "./admin/productEdit";
import AdminOrderCreate from "./admin/order";
import AdminOrderList from "./admin/orderList";
import AdminOrderEdit from "./admin/orderDetail";
import AdminOrderUpdate from "./admin/orderUpdate";
import AdminUserList from "./admin/userList";
import AdminUserAdd from "./admin/addUser";
import Footer from '../components/reusable/admin/Footer';

const AppRoutes = () => {
  const token = localStorage.getItem('token');

  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "F1") {
        event.preventDefault();
        navigate("/");
      }
      else if (event.key === "F2") {
        event.preventDefault();
        navigate("/admin/dashboard_with_gst");
      }
      else if (event.key === "F3") {
        event.preventDefault();
        navigate("/admin/product_list");
      }
      else if (event.key === "F4") {
        event.preventDefault();
        navigate("/admin/product");
      }
      else if (event.key === "F5") {
        event.preventDefault();
        navigate("/admin/order/create");
      }
      else if (event.key === "F6") {
        event.preventDefault();
        navigate("admin/order_list");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate]);

  return (
    <>
      <Routes>
        <Route path="/" element={token ? <Dashboard /> : <Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={token ? <Dashboard /> : <Navigate to="/login" />} />
        <Route path="/admin/dashboard_with_gst" element={token ? <DashboardWithGst /> : <Navigate to="/login" />} />
        <Route path="/admin/product" element={token ? <Product /> : <Navigate to="/login" />} />
        <Route path="/admin/product_list" element={token ? <AdminProductList /> : <Navigate to="/login" />} />
        <Route path="/admin/product_edit/:productId" element={token ? <AdminProductEdit /> : <Navigate to="/login" />} />
        <Route path="/admin/order/create" element={token ? <AdminOrderCreate /> : <Navigate to="/login" />} />
        <Route path="/admin/order_list" element={token ? <AdminOrderList /> : <Navigate to="/login" />} />
        <Route path="/admin/order_edit/:orderId" element={token ? <AdminOrderEdit /> : <Navigate to="/login" />} />
        <Route path="/admin/order_update/:orderId" element={token ? <AdminOrderUpdate /> : <Navigate to="/login" />} />
        <Route path="/admin/users_list" element={token ? <AdminUserList /> : <Navigate to="/login" />} />
        <Route path="/admin/users_add" element={token ? <AdminUserAdd /> : <Navigate to="/login" />} />
      </Routes>
      <Footer />
    </>
  );
};

export default AppRoutes;
